import React from 'react'

import carousel_1 from "../images/carousel-1.jpg"

const Caroussel = () => {
    return (
        <div className="container-fluid p-0 pb-5">
            <div className="owl-carousel header-carousel position-relative mb-5 owl-loaded">
                <div className="owl-carousel-item position-relative">
                    <img src={carousel_1} alt="" height={'500px'} width={'100%'} />
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(6, 3, 21, .5)" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-10 col-lg-8 text-center">
                                    <h5 className="text-primary text-uppercase mb-3 animated slideInDown">Solution de transport et de logistique</h5>
                                    <h3 className="display-3 text-white animated slideInDown mb-3">Suivez vos colis</h3>
                                    <form action="/tracking" method='get' className='d-flex'>
                                        <input type="text" maxLength={10} name="numero" id="id" className='form-control border-0 py-md-3 px-md-5' required placeholder='Saisissez votre numéro de colis' />
                                        <button className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft" type="submit">Suivre</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Caroussel