import React from 'react'
import tracking from "../images/tracking.jpg"

const Tracking = () => {
    return (
        <div className="container-fluid overflow-hidden py-5 px-lg-0" id='about'>
            <div className="container about py-5 px-lg-0">
                <div className="row g-5 mx-lg-0">
                    <div className="col-lg-6 ps-lg-0 wow fadeInLeft" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute img-fluid w-100 h-100" src={tracking} style={{ objectFit: "cover" }} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 about-text wow fadeInUp" data-wow-delay="0.3s">
                        <h6 className="text-secondary text-uppercase mb-3">Tracking</h6>
                        <h1 className="mb-3">Gardez un oeil sur votre colis en temps réel</h1>
                        <h3 className="mb-3">De la Chine au Burkina Faso</h3>
                        <p className="mb-3" style={{ 'textAlign': 'justify' }}>Avec notre système de suivi, gardez un oeil sur votre expédition à chaque étape. Entrez votre numéro de paquet et restez connecté à votre colis. Simple, rapide et efficace. <br /> Commencez le suivi dès maintenant</p>
                        <div className="row g-4 mb-5">
                            <form action={'/tracking'} method='get' className="col-sm-12 wow fadeIn" data-wow-delay="0.5s">
                                <i className="fa fa-map-marker fa-3x text-primary mb-3"></i>
                                <h5><label htmlFor="paquet" className='form-label'>Informations du paquet</label></h5>
                                <div className='d-flex'>
                                    <input type="text" maxLength={10} name="numero" id="paquet" className='form-control py-md-3 px-md-5' placeholder='Saisissez votre numéro de paquet' required />
                                    <button className="btn btn-primary py-md-3 px-md-5" type="submit">Suivre</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tracking